/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import "@stripe/stripe-js"
import Amplify from "aws-amplify"
import awsconfig from "./src/aws-exports"
import Layout from "./src/components/layout"
// import MessengerChat from "./src/components/messenger"
import GlobalContextProvider from "./src/context/GlobalContextProvider"
Amplify.configure(awsconfig)

function setApplicationMonitor() {
  // see: https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM.html
  ;(function (n, i, v, r, s, c, x, z) {
    x = window.AwsRumClient = {
      q: [],
      n: n,
      i: i,
      v: v,
      r: r,
      c: c,
    }
    window[n] = function (c, p) {
      x.q.push({ c: c, p: p })
    }
    z = document.createElement("script")
    z.async = true
    z.src = s
    document.head.insertBefore(
      z,
      document.getElementsByTagName("script")[0]
    )
  })(
    "cwr",
    "1de484d0-ebc4-498b-874b-4c7eb98698ea",
    "1.0.0",
    "ap-southeast-1",
    "https://client.rum.us-east-1.amazonaws.com/1.0.2/cwr.js",
    {
      sessionSampleRate: 1,
      guestRoleArn:
        "arn:aws:iam::517820625953:role/RUM-Monitor-ap-southeast-1-517820625953-5350365269361-Unauth",
      identityPoolId:
        "ap-southeast-1:46878b6d-4667-443e-a2b0-daac0fa1e6e3",
      endpoint:
        "https://dataplane.rum.ap-southeast-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
    }
  )
}

export const onInitialClientRender = () => {
 setApplicationMonitor()
}

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalContextProvider>{element}</GlobalContextProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
