/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "../assets/stylesheets/layout.scss"
import { theme } from "../assets/theme"
import { Grommet } from "grommet"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import { useMediaQuery } from "react-responsive"

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const isigScreen = useMediaQuery({
    query: "(min-width: 768px)",
  })

  useEffect(() => {
    if (isigScreen) {
      setIsMenuOpen(false)
    }
    return () => {
      setIsMenuOpen(false)
    }
  }, [isigScreen])

  return (
    <Grommet
      theme={theme}
      className={isMenuOpen && "hiddenMain"}
    >
      <Header
        isMenuOpen={isMenuOpen}
        handleClick={handleClick}
      />
      <main className="mainTopWrapper">{children}</main>
      <Footer />
    </Grommet>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
