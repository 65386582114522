import React from "react"
import "./footer.scss"
import { Link as GatsbyLink } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

const Footer = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(
          relativePath: { eq: "footer-bg.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const FooterBGImage = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage fluid={FooterBGImage}>
      <footer className="footer">
        <div className="container">
          <div className="footerTop">
            <div className="footerBlockLeft">
              <h3 className="footerTextTitle">
                Get Professional Certificates and Standard
                BIM Training
              </h3>
            </div>
            <div className="footerBlockCenter">
              <h3 className="footerTopTitleCenter">
                Resources
              </h3>
              <ul className="footerTopMenu">
                <li clas="footerTopMenuItem">
                  <GatsbyLink
                    to="/courses-autodesk"
                    className="footerTopMenuLink"
                  >
                    Autodesk Courses
                  </GatsbyLink>
                </li>
                <li clas="footerTopMenuItem">
                  <GatsbyLink
                    to="/courses-bentley"
                    className="footerTopMenuLink"
                  >
                    Bentley Courses
                  </GatsbyLink>
                </li>
                <li clas="footerTopMenuItem">
                  <GatsbyLink
                    to="/courses-bim"
                    className="footerTopMenuLink"
                  >
                    BIM Courses
                  </GatsbyLink>
                </li>
                <li clas="footerTopMenuItem">
                  <GatsbyLink
                    to="/courses-skillsfuture"
                    className="footerTopMenuLink"
                  >
                    SkillsFuture Courses
                  </GatsbyLink>
                </li>
                {/* <li clas="footerTopMenuItem">
                  <GatsbyLink
                    to="/internal/forms"
                    className="footerTopMenuLink"
                  >
                    Employee Resources
                  </GatsbyLink>
                </li> */}
              </ul>
            </div>
            <div className="footerBlockRight">
              <GatsbyLink
                to="/contact"
                className="footerBlockButton"
              >
                Contact
              </GatsbyLink>
            </div>
          </div>

          <div className="footerCenter">
            <ul className="footerCenterMenu">
              <li className="footerCenterMenuItem">
                <GatsbyLink
                  to="/"
                  className="footerCenterMenuLink"
                >
                  HOME
                </GatsbyLink>
              </li>
              <li className="footerCenterMenuItem">
                <GatsbyLink
                  to="/blog"
                  className="footerCenterMenuLink"
                >
                  BLOG
                </GatsbyLink>
              </li>
              <li className="footerCenterMenuItem">
                <GatsbyLink
                  to="/courses"
                  className="footerCenterMenuLink"
                >
                  ALL COURSES
                </GatsbyLink>
              </li>
              <li className="footerCenterMenuItem">
                <GatsbyLink
                  to="/certifications"
                  className="footerCenterMenuLink"
                >
                  CERTIFICATIONS
                </GatsbyLink>
              </li>
            </ul>
          </div>

          <div className="footerBottom">
            <p className="copyright">
              Copyright © 2022 | Ace Industrial Academy |
              UEN: 200710151K
            </p>
          </div>
        </div>
      </footer>
    </BackgroundImage>
  )
}

export default Footer
