import React, {
  createContext,
  useReducer,
  useEffect,
  ReactNode,
} from "react"
import { isBrowser } from "./utils"

interface ReducerAction {
  type: String
  payload: String
}

interface GlobalContextProps {
  children?: ReactNode
}

interface GlobalContextState {
  courses: Array<String | null>
  featured: Array<String>
  autodesk: Array<String>
  bentley: Array<String>
  courseSearchQuery: string
  searchResultsCourses: Array<String | null>
  filtersObject: any
  filteredCoursesList: Array<String | null>
  selectedFilterOptions: Array<string>
}

const initialValue: GlobalContextState = {
  courses: [],
  featured: [],
  autodesk: [],
  bentley: [],
  filtersObject: {
    categories: {
      1: false,
      2: false,
      3: false,
    },
    prices: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
    },
    filteredCoursesList: [],
    selectedFilterOptions: [],
  },
}

export const GlobalStateContext =
  createContext<GlobalContextState | null>(null)
export const GlobalDispatchContext = createContext(null)

const initializer = () => {
  if (!isBrowser()) return

  let hasLocal = localStorage.getItem("localProvider")
  if (hasLocal) return JSON.parse(hasLocal)

  const initialValue: GlobalContextState = {
    courses: [],
    featured: [],
    courseSearchQuery: "",
    searchResultsCourses: [],
    filtersObject: {
      categories: {
        1: false,
        2: false,
        3: false,
        // 4: false,
        // 5: false,
      },
      prices: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      // ratings: {
      //   1: false,
      //   2: false,
      //   3: false,
      //   4: false,
      //   5: false,
      // },
      // difficulties: {
      //   1: false,
      //   2: false,
      //   3: false,
      // },
      // skills: {
      //   1: false,
      //   2: false,
      //   3: false,
      // },
    },
    filteredCoursesList: [],
    selectedFilterOptions: [],
  }
  return initialValue
}

const reducer = (
  state: GlobalContextState,
  action: ReducerAction
) => {
  switch (action.type) {
    case "SET_COURSES": {
      return {
        ...state,
        courses: action.payload,
      }
    }
    case "SET_FEATURED": {
      return {
        ...state,
        featured: action.payload,
      }
    }
    case "SET_AUTODESK": {
      return {
        ...state,
        autodesk: action.payload,
      }
    }
    case "SET_BENTLEY": {
      return {
        ...state,
        bentley: action.payload,
      }
    }
    case "SET_COURSE_SEARCH_QUERY": {
      return {
        ...state,
        courseSearchQuery: action.payload,
      }
    }
    case "SET_COURSE_SEARCH_RESULTS": {
      return {
        ...state,
        searchResultsCourses: action.payload,
      }
    }
    case "SET_FILTER_VALUES": {
      return {
        ...state,
        filtersObject: action.payload,
      }
    }
    case "CLEAR_FILTER_VALUES": {
      return {
        ...state,
        filtersObject: initialValue.filtersObject,
      }
    }
    case "SET_FILTERED_COURSES": {
      return {
        ...state,
        filteredCoursesList: action.payload,
      }
    }
    case "SET_FILTER_OPTIONS": {
      return {
        ...state,
        selectedFilterOptions: action.payload,
      }
    }
  }
}

const GlobalContextProvider = ({
  children,
}: GlobalContextProps) => {
  const [state, dispatch] = useReducer(
    reducer,
    [],
    initializer
  )

  useEffect(() => {
    try {
      localStorage.setItem(
        "localProvider",
        JSON.stringify(state)
      )
    } catch (err) {
      console.log("unable to set localStorage", err)
    }
  }, [state])

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
